import { createStyles, Header, Container, Group, Burger, Paper, Transition, Title, Text, ActionIcon, useMantineColorScheme } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from "react-i18next";
import { IconSun, IconMoonStars } from '@tabler/icons';
import { Link } from "react-router-dom";

const HEADER_HEIGHT = 70;

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    }
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

interface HeaderResponsiveProps {
  links: { link: string; label: string }[];
}

export function HeaderResponsive({ links }: HeaderResponsiveProps) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const active = useLocation().pathname;
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const { classes, cx } = useStyles();

  const items = links.map((link) => (
    <Link
      key={link.label}
      to={link.link}
      className={cx(classes.link, { [classes.linkActive]: active === link.link })}
      onClick={() => {
        close();
      }}
    >
      <Text transform='uppercase'>{link.label}</Text>
    </Link>
  ));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  items.push(
    <>
      <Group>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
        <Link
          to="#"
          className={classes.link}
          onClick={() => {
          i18n.changeLanguage(i18n.language === "de" ? "en" : "de");
          close();
          }}
        >
          <Text transform='uppercase'>{i18n.language === "de" ? "de" : "en"}</Text>
        </Link>
        <ActionIcon variant='outline' color={dark ? 'white' : 'black'} onClick={() => {toggleColorScheme(); close();}} title="Toggle Color Schema">
          {dark ? <IconSun size={18}/> : <IconMoonStars size={18}/>}
        </ActionIcon>
      </Group>
    </>
    );


  return (
    <Header height={HEADER_HEIGHT} mb={50} className={classes.root}>
      <Container className={classes.header}>
        <Link
          to="/"
          className={classes.link}
          onClick={() => {
            close();
          }}
        >
          <Title order={2}>Ronan Lindörfer</Title>
        </Link>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>

        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" color={dark ? 'gray' : 'gray'} />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}

