import { createStyles, Container, Group, ActionIcon, Text } from '@mantine/core';
import { IconBrandLinkedin, IconBrandInstagram, IconCopyright } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 120,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export function FooterSocial() {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Group>
            <IconCopyright size={18} stroke={1.5} />
            <Text size={'sm'}> Ronan Lindörfer 2022</Text>
        </Group>
        <Group spacing={0} className={classes.links} position="right" noWrap>
            <a href='https://www.linkedin.com/in/ronan-lindörfer-003809231' target={'_blank'} rel="noreferrer">
                <ActionIcon size="lg">
                    <IconBrandLinkedin size={20} stroke={1.5} />
                </ActionIcon>
            </a>
            <a href='https://www.instagram.com/rli1511/' target={'_blank'} rel="noreferrer">
            <ActionIcon size="lg">
                <IconBrandInstagram size={20} stroke={1.5} />
            </ActionIcon>
            </a>
        </Group>
      </Container>
    </div>
  );
}