export const i18n_german = {
    translation: {
        lang: "de",
        menu: {
            home: "home",
            about: "über mich",
            projects: "projekte"
        },
        home: {
            text1: "Herzlich willkommen auf meiner persönlichen Webseite. Zu finden sind Projekte die meine Motivation und Interesse zeigen wie auch Informationen zu meiner Person. Diese ganze Seite ist z.B. zustande gekommen, da ich motiviert war, React zu lernen und in einen ersten Projekt anzuwenden. Zum Zeitpunkt der Idee war ich eh gerade daran Bewerbungen zu schreiben und somit entstand diese Webseite.",
            textProjects: "Ein Übersicht von meine Projekten aus dem Studium und meinen Vereinsaktivitäten.",
            toProjects: "Zu den Projekten",
            textAbout: "Was wie wo ich schon in meinem Leben aktiv war, ist unter über mich aufgelistet.",
            toAbout: "Zu meinem Profil",
        },
        about: {
            title: "Zu meiner Person",
            jetzt: "jetzt",
            person: {
                title: "Persönliches",
                anrede: "Anrede",
                vorname: "Vorname",
                nachname: "Nachname",
                nationality: "Nationalität",
                nationalityText: "Deutsch mit schweizer Aufenthaltstitel C",
            },
            jobs: {
                title: "Berufserfahrung",
                support: {
                    text: "Verband der Studierenden an der ETH Zürich als Supporter in der Informatik-Service-Gruppe:",
                    p1: "Wartung und Patching von IT-Systemen (Linux und Windows)",
                    p2: "Wartung von Softwarelösungen in VMs und Containern",
                    p3: "Containerisierung von Webanwendungen (z.B WordPress)",
                    p4: "allg. 1st, 2nd & 3rd Level Support",
                },
                ingenieur: {
                    text: "Verband der Studierenden an der ETH Zürich als Systemingenieur:",
                    p1: "Aufräumen und Ablösung von legacy Systemen",
                    p2: "Aufbau einer neuen IT-infrastruktur mit Kubernetes",
                    p3: "Containerisierung von Webanwendungen",
                }
            },
            facts: {
                title: "Kenntisse/Erfahrungen",
                lang: "Fremdsprachen",
                langText1: "Deutsch Muttersprache",
                langText2: "Englisch schriftlich und mündlich gut",
                gPL: "Bevorzugte Programmier - sprachen",
                wPL: "Weitere Programmier - sprachen",
                web: "Web-Technologie",
                db: "Datenbanken",
                tools: "Weitere Tools",
                interessen: "Interessen",
                interessenText: "High Performance Computing, Computer Vision, Machine Learning, Wissenschaftliche Programmierung"
            },
            school: {
                title: "Schuliche Ausbildung",
                msc: "Master-Studium Rechnergestützte Wissenschaften an der ETH Zürich",
                bsc: "Bachelor-Studium Rechnergestützte Wissenschaften an der ETH Zürich",
                matura: "Gymnasium an der Kantonsschule Trogen AR"
            },
            society: {
                title: "Vereinstätigkeiten",
                sek: "SEK - Softwareentwicklungskommission des VSETHs erst im Vorstand und inzwischen als Präsident. Wir unterstützen Studierende, die Webentwicklung kennen lernen möchten und bieten spannende Projekte im Verband an.",
                flik: "FLiK - Freiluftlichtbildschau Kommission des VSETHs im Ressort Vizepräsident und später als Präsident. 09.2021 dann entlich nach Corona organisation eines dreitägigen Openair Kinos.",
                vseth: "VSETH - Verband der Studierenden an der ETH Zürich Vorstand Ressort IT. Strategische Planung und Aufbau einer neuen IT Infrastruktur um Aktives im Verband und den Sektionen in ihre Arbeit zu unterstützen.",
                vmp: "VMP - Verein der Mathematik-, Physik und Rechnergestützte Wissenschaftenstudierenden an der ETH Zürich als IT-Vorstand und Vizepräsident. In der Zeit habe ich dort ein Server mit Mailserver, Webserver und Dateiablage komplett neu aufgesetzt.",
                vk: "Verkehrskadetten Appenzellerland mit Aufstieg bis zum Rang Zugführer"
            },
            hobbies:{
                title: "Freizeit",
                text: "Radfahren, Skifahren (J+S Leiter), Kochen, Im Verein Events organisieren, private IT-Projekte, Brettspiele"
            }
        },
        projects: {
            title: "Vergangene Projekte",
            TitleHPC: "Vorlesungsprojekt: High Perfomance Computing",
            titleSEK: "Sofware Entwicklungs Kommission",
            titleMsc: "Masterarbeit: Spontaneous Speaker Recognition for Conversation Estimation",
            TitleWP: "Containerisierung von WordPress für die IT Inrastucture des VSETHs",
            TitleBsc: "Bachelorarbeit: Particle Tracking for Plant Cell Study with an Adaptive Optical Flow Method",
            TitleHtwfnc: "Vorlesungsprojekt: How to Write Fast Numerical Code",
            titleFLiK: "FLiK - Freiluftlichtbildschau Kommission",
            TitlePbs: "Vorlesungsprojekt: Physical based Simulation",
            TitleMatura: "Maturaarbeit: 3D Modelierung der Kantonsschule Trogen",
            TextSEK: "Die Softwareentwicklungskommission des VSETH unterstützt und fördert Studierenden an der ETH wie man Webentwicklung lernen und Projekte umsetzten kann. Dabei schafft sie ein Rahmen in dem schnell Probleme und Hindernisse mit erfahrenen Kommissionsmitgliedern anschauen und lösen kann. Momentan leite ich als Präsident die Kommission und organisiere die wöchentlichen Coding Sessions. Mit meinen ca. fünf Jahren Erfahrung an Softwareentwicklung im VSETH Umfeld, betreue ich Projekte von komplett neu Einsteigern oder gebe Feedback Projekten von erfahrenen Entwicklern.",
            TextMsc: "Die Idee war Psychologen oder Gruppen Coachs bei der Auswertung von Gruppengesprächen zu helfen. Oft wird dabei geschaut, wie der Redeanteil zwischen den verschiedenen Gesprächsteilnehmenden aufgeteilt ist. Somit lässt sich die Dynamik einer Gruppe auch mit Zahlen bestimmen. Meine Aufgabe war Aufnahmen, bei denen ich die einzelne Stimmen nicht separat schon in Vorhinein kenne, aus einander zu separieren. Der erste Versuch des Labelings durch Feature Extraction und dann simplen Clustering war leider nicht erfolgreich. Somit wechselte ich auf Unsupervised Learning und trainierte ein Neuronalesnetzwerk.",
            TextFLiK: "2021 war ich Präsident der Freiluftlichtblidschau Kommission des VSETH's. Noch während Corona habe ich mit meinem achtköpfigen Vorstand ein dreitägiges Open Air Kino auf dem Hönggerberg Campus der ETH Zürich organisiert. Meine Aufgabe als Präsident war das bisherige Eventkonzept von vor Corona für die Massnahmen im Herbst 2021 anzupassen. Schlussendliche mussten wir, obwohl wir keinen Eintritt verlangten, mussten wir das Gelände umzäunen und am Eingang eine Zertifikatskontrolle machen. Auf Masken konnten wir zum Glück innerhalb des Areals, dank der freien Luft, verzichten. Schlussendlich ist an den Abenden mit im Schnitt 400 Besuchern alles Reibungslos gelaufen.",
            TextHPC: "Die Aufgabe war eine N-Body Simulation zu schreiben und diese dann zu parallelisieren. In der Simulation wurden bis zu 100'000 Eisenkugeln berechnet, die in einer rotierenden Box sind. Die Kugeln stossen mit eine elastischen Stoss zusammen und haben auch Eigenrotation bei Kontakt übertragen können. Die Ränder wurden mit gespiegelten Ghostpartikeln umgesetzt. Für bessere Performance mussten wir die Multigrid Methode verwenden, um alle Kollisionen zu bestimmen. Diese haben wir dann mit OpenMP paralelliziert. Schwierig war dabei, die Werte der physikalischen Grössen in eine Bereich der numerischen Stabilität zu behalten ohne zu hohe Impulse zu generieren.",
            TextHtwfnc:"Die Aufgabe war einen Algorithmus, der schon von der Methode her optimal läuft, an eine selbst gewählte Computerarchitektur anzupassen. Dabei sollten wir uns auf Single Core beschränken. Meine Gruppe hat sich auf eine Anwendung von Belief Propagation auf Bilder fokussiert. Durch Belief Propagation kann man den Vorder- und Hintergrund separieren. Mein Anteil, war bei den mehreren Iterationen über das ganze Bild, die Schlaufen so anzupassen, dass die Daten im Cache optimal verwendet werden und diese so wenig häufig wie möglich geladen werden. Am Schluss bekamen wir eine deutliche Performance Steigerung gegenüber der ursprünglichen Implementierung.",
            TextWP: "Der Verband der Studierende hatte eine dynamisch gewachsene IT Infrastruktur. Diese basierte auf virtuellen Maschinen, die von der Kommission oder Fachverein komplett selbständig eingerichtet und gewartet wird. Diese VM's wurden nicht immer gut gepflegt. Das meist eingesetzte CMS war WordPress. 2018 Hat dann der VSETH entschieden auf eine containerbasierten Lösung zu wechseln. Meine Aufgabe war, WordPress so zu containerisieren, dass es sauber mit unserem Umsystem wie Datenbank, Volumes, OpenID Connect inklusive Rollen und SMTP automatisch hochfährt. In zwischen laufen, durch diese automatischen Konfiguration 26 Instanzen. Diese laufen alle mit dem selben Containerimage und unterscheiden sich nur durch die Umgebungsvariablen und somit eine andere Datenbank und Volumen.",
            TextPbs: "Wir mussten eine Simulationsmethode von der Vorlesung aussuchen und diese mit einer kreativen Idee anwenden. Wir entschieden uns für eine Fluidpartikel Simulation unter Berücksichtigung von Portalen. Wie man sie aus dem Computerspiel Portal kennt. Dabei haben einerseits die Numerik für die Physik implementiert und anderseits die Datenstruktur so angepasst, dass die Suche nach Partikeln in der Nachbarschaft auch durch die Portale hindurch geht.",
            TextBsc: "Die Aufgabenstellung kam aus der Biologie. Pollenschläuche sind Zellen, die vergleichsweise schnell wachsen und dies sogar in eine gezielte Richtung. Vermutung gehen davon aus, dass die Strömung der Partikeln in der Zelle selber, die Richtung steuert. Um das genauer zu bestimmen, braucht es eine Software, die diese Strömung bestimmt. Meine Aufgabe war von den optischen Mikroskopaufnahmen per Methoden der Bilderkennung die Partikel zu verfolgen und ein Strömungsbild zu erstellen.",
            TextMatura: "Ich gab mir die Aufgabe digitale 3D Modellierung anhand einer ersten Anwendung kennen zu lernen. Das Ziel war die Gebäude der Kantonsschule Trogen AR für Google Earth zu modellieren. Mit Hilfe der Bauplänen und Google Sketchup konnte ich sehr detailgetreue 3D Modelle der Gebäude produzieren. Leider war es am Schluss dann für Google Earth zu detailliert und die Gebäude wurden nicht in den öffentlichen Datensatz übernommen.",
        }
    }
}; 