import { createStyles, Container, ColorScheme} from "@mantine/core";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HeaderResponsive } from "./components/menu";
import { FooterSocial } from "./components/fooder";
import MyThemeProvider from "./components/themeProvider"

import { HomePage } from "./pages/home";
import { About } from "./pages/about";
import { Projects } from "./pages/projects";
import { useLocalStorage } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
	content: {
		maxWidth: 960,
    paddingLeft: 16,
    paddingRight: 16,
		width: "100%"
	},
}));

export default function App() {
  const { t } = useTranslation();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });
  const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  
  const { classes } = useStyles();

  let pageList = [
    {link: "/", label: t("menu.home"), element: <HomePage/>},
    {link: "/projects", label: t("menu.projects"), element: <Projects/>},
    {link: "/about", label: t("menu.about"), element: <About/>},
  ];

  const routeList = pageList.map((page) => (
    <Route path={page.link} element={page.element} />
  ));
  
  
  return (
    <MyThemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <BrowserRouter>
        <HeaderResponsive links={pageList}/>
            <Container className={classes.content}>
              <Routes>
                {routeList}
              </Routes>
            </Container>
        <FooterSocial/>
      </BrowserRouter>
    </MyThemeProvider>
  );
}
