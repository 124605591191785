import { createStyles } from "@mantine/core";


export const customStyle = createStyles((theme) => ({
	card: {
		maxWidth: '100%',
        margin: 'auto',
        marginBottom: '15pt',
        borderStyle: theme.colorScheme === 'dark' ? 'none' : 'solid',
        borderWidth: 'thin',
        borderColor: theme.colors.gray[7],
	},
    cardText: {
        textAlign: 'justify'
    },
    badges: {
        margin: '3px'
    },
    carouselSlide: {
        margin: 'auto',
    },
    carouselControl: {
        background: 'none',
        borderWidth: 0,
        boxShadow: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : '#000',
        opacity: 1,
    },
    carouselIndicator: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[0] : '#000',
        opacity: 0.4,
    },
    titleBanner: {
        backgroundImage: "url('img/home.jpg')",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginBottom: '10px',
    },
    frontImage: {
        width: '85%',
        borderRadius: '10px',
        paddingTop: '5px',
    },
}));