import { Button, Card, Divider, Grid, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { customStyle } from "../components/customStyle";

export function HomePage(){
    const { t } = useTranslation();
    const { classes } = customStyle();

    return (
        <>
            <div className={classes.titleBanner}>
                <Grid>
                    <Grid.Col span={8} />
                    <Grid.Col span={4}>
                        <img src="img/front.jpg" className={classes.frontImage} alt="ProfilBild"/>
                    </Grid.Col>
                </Grid>
            </div>
            <Text align="justify">{t("home.text1")}</Text>
            <Divider my="md"/>
            <Grid>
                <Grid.Col sm={6} xs={12}>
                    <Card className={classes.card} style={{textAlign: 'center'}}>
                        <Title order={2}>{t("projects.title")}</Title>
                        <Text>{t("home.textProjects")}</Text>
                        <Button component={Link} to="/projects">{t("home.toProjects")}</Button>
                    </Card>
                </Grid.Col>
                <Grid.Col sm={6} xs={12}>
                    <Card className={classes.card} style={{textAlign: 'center'}}>
                        <Title order={2}>{t("about.title")}</Title>
                        <Text>{t("home.textAbout")}</Text>
                        <Button component={Link} to="/about">{t("home.toAbout")}</Button>
                    </Card>
                </Grid.Col>
            </Grid>
        </>
    )
};
