import { ColorScheme, ColorSchemeProvider, MantineProvider, MantineThemeOverride } from "@mantine/core";

function myTheme (cScheme: ColorScheme){
    const theme: MantineThemeOverride = {
        colorScheme: cScheme,
        colors: {
            // 'olive': ['#778a35'],
            // 'gray': ['#d1e2c4'],
            // 'white': ['#ebebe8'],
            // 'dark': ['#31352e']
            'olive': ['#f5f8ed', '#e2eac8', '#cfdca3', '#bccd7e', '#a8bf59', '#8fa640', '#6f8132', '#4f5c23', '#303715', '#101207'],
            'gray': ['#f2f7ee', '#d7e6cc', '#bcd5aa', '#a2c487', '#87b365', '#6e9a4c', '#55783b', '#3d552a', '#253319', '#0c1108'],
            'white': ['#f3f3f1', '#dbdbd6', '#c4c4bb', '#acaca0', '#949484', '#7b7b6b', '#5f5f53', '#44443b', '#292924', '#292924'],
            'dark': ['#f2f3f1', '#d8dbd6', '#bfc4bb', '#a5ac9f', '#8b9484', '#727b6b', '#586053', '#3f443b', '#3f443b', '#0d0e0c']
        },
        primaryColor: "olive",
        primaryShade: 6,
        white: "white",
        black: "dark"
    };
    return theme;
}


interface Props {
    colorScheme: ColorScheme;
    toggleColorScheme(colorScheme?: ColorScheme): void;
    /**
    Children rendered in the main area
    */
    children?: React.ReactNode;
}
const MyThemeProvider: React.FC<Props> = ({colorScheme, toggleColorScheme, children}) => (
    <>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider theme={myTheme(colorScheme)} withGlobalStyles withNormalizeCSS>
                {children}
            </MantineProvider>
        </ColorSchemeProvider>
    </>
);

export default MyThemeProvider;