import { Title, Text, Grid, Card, Image, Badge, Space } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Player, BigPlayButton } from 'video-react';
import { Carousel } from '@mantine/carousel';
import { customStyle } from "../components/customStyle";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";


export function Projects(){
    const { t } = useTranslation();
    const { classes } = customStyle();
    return (
        <>
            <Title>{t("projects.title")}</Title>
            <Space h="md"/>
            <Card className={classes.card}>
               <Grid>
                   <Grid.Col sm={7} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.titleSEK")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2022</Text>
                            </Grid.Col>
                        </Grid>
                        <Text className={classes.cardText}>{t("projects.TextSEK")} (<a href="https://sek.vseth.ethz.ch/" target="_blank" rel="noreferrer">Website</a>)</Text>
                        <Badge className={classes.badges}>C++</Badge>
                        <Badge className={classes.badges}>CI/CD</Badge>
                        <Badge className={classes.badges}>Docker</Badge>
                        <Badge className={classes.badges}>Kubernetes</Badge>
                        <Badge className={classes.badges}>OpenID Connect</Badge>
                   </Grid.Col>
                   <Grid.Col sm={5} xs={12}>
                        <Carousel sx={{ maxWidth: 320 }} mx="auto" withIndicators withKeyboardEvents slideGap="sm" loop classNames={{control: classes.carouselControl, indicator: classes.carouselIndicator}} previousControlIcon={<IconChevronLeft size='24pt'/>} nextControlIcon={<IconChevronRight size='24pt'/>}>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/sek/codingWE.jpg"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/sek/Workshop.jpg"/>
                            </Carousel.Slide>
                        </Carousel>
                   </Grid.Col>
               </Grid>
            </Card>

            <Card className={classes.card}>
                <Grid>
                    <Grid.Col sm={7} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.titleMsc")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2021/22</Text>
                            </Grid.Col>
                        </Grid>
                        <Text className={classes.cardText}>{t("projects.TextMsc")} (<a href="pdfs/pd_z Report.pdf">Thesis</a>)</Text>
                        <Badge className={classes.badges}>Python</Badge>
                        <Badge className={classes.badges}>librosa</Badge>
                        <Badge className={classes.badges}>Tensorflow</Badge>
                    </Grid.Col>
                    <Grid.Col sm={5} xs={12}>
                        <Carousel sx={{ maxWidth: 320 }} mx="auto" withIndicators withKeyboardEvents slideGap="sm" loop classNames={{control: classes.carouselControl, indicator: classes.carouselIndicator}} previousControlIcon={<IconChevronLeft size='24pt'/>} nextControlIcon={<IconChevronRight size='24pt'/>}>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/msc/idea.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/msc/representations.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/msc/impl.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/msc/autoencoder.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/msc/resultsplot.png"/>
                            </Carousel.Slide>
                        </Carousel>
                    </Grid.Col>
                </Grid>
            </Card>

            <Card className={classes.card}>
               <Grid>
                   <Grid.Col sm={7} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.titleFLiK")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2021</Text>
                            </Grid.Col>
                        </Grid>
                        <Text className={classes.cardText}>{t("projects.TextFLiK")} (<a href="https://flik.ethz.ch/" target="_blank" rel="noreferrer">Website</a>)</Text>
                        <Badge className={classes.badges}>OpenAir Cinema</Badge>
                        <Badge className={classes.badges}>Eventorganisation</Badge>
                   </Grid.Col>
                   <Grid.Col sm={5} xs={12}>
                        <Carousel sx={{ maxWidth: 320 }} mx="auto" withIndicators withKeyboardEvents slideGap="sm" loop classNames={{control: classes.carouselControl, indicator: classes.carouselIndicator}} previousControlIcon={<IconChevronLeft size='24pt'/>} nextControlIcon={<IconChevronRight size='24pt'/>}>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/flik/1.jpg"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/flik/2.jpg"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/flik/3.jpg"/>
                            </Carousel.Slide>
                        </Carousel>
                   </Grid.Col>
               </Grid>
            </Card>

            <Card className={classes.card}>
               <Grid>
                   <Grid.Col sm={7} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.TitleHtwfnc")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2019</Text>
                            </Grid.Col>
                        </Grid>
                       <Text className={classes.cardText}>{t("projects.TextHtwfnc")} (<a href="pdfs/Belief_Propagation_for_Early_Vision.pdf">Report</a>)</Text>
                       <Badge className={classes.badges}>C++</Badge>
                       <Badge className={classes.badges}>Intel Intrinsics</Badge>
                   </Grid.Col>
                   <Grid.Col sm={5} xs={12}>
                        <Carousel sx={{ maxWidth: 320 }} mx="auto" withIndicators withKeyboardEvents slideGap="sm" loop classNames={{control: classes.carouselControl, indicator: classes.carouselIndicator}} previousControlIcon={<IconChevronLeft size='24pt'/>} nextControlIcon={<IconChevronRight size='24pt'/>}>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/fastCode/old.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/fastCode/better.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/fastCode/side.png"/>
                            </Carousel.Slide>
                        </Carousel>
                   </Grid.Col>
               </Grid>
            </Card>

            <Card className={classes.card}>
                <Grid>
                    <Grid.Col sm={12} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.TitleWP")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2018/19</Text>
                            </Grid.Col>
                        </Grid>
                        <Text className={classes.cardText}>{t("projects.TextWP")}</Text>
                        <Badge className={classes.badges}>Bash</Badge>
                        <Badge className={classes.badges}>PHP</Badge>
                        <Badge className={classes.badges}>Docker</Badge>
                        <Badge className={classes.badges}>Kubernetes</Badge>
                        <Badge className={classes.badges}>OpenID Connect</Badge>
                    </Grid.Col>
                </Grid>
            </Card>

            <Card className={classes.card}>
                <Grid>
                    <Grid.Col sm={7} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.TitleHPC")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2017/18</Text>
                            </Grid.Col>
                        </Grid>
                        <Text className={classes.cardText}>{t("projects.TextHPC")} (<a href="pdfs/Report_Ronan.pdf">Report</a>)</Text>
                        <Badge className={classes.badges}>C++</Badge>
                        <Badge className={classes.badges}>OpenMP</Badge>
                        <Badge className={classes.badges}>GLUT (OPENGL)</Badge>
                    </Grid.Col>
                    <Grid.Col sm={5} xs={12} style={{position: 'relative'}}>
                        <Player src="video/hpc_project.webm" muted={true}>
                            <BigPlayButton position="center" />
                        </Player>
                    </Grid.Col>
                </Grid>
            </Card>

            <Card className={classes.card}>
               <Grid>
                   <Grid.Col sm={7} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.TitlePbs")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2017</Text>
                            </Grid.Col>
                        </Grid>
                       <Text className={classes.cardText}>{t("projects.TextPbs")}</Text>
                       <Badge className={classes.badges}>C++</Badge>
                       <Badge className={classes.badges}>GLUT (OpenGL)</Badge>
                   </Grid.Col>
                   <Grid.Col sm={5} xs={12}>
                        <Player src="video/pbs_project.webm" muted={true}>
                            <BigPlayButton position="center" />
                        </Player>
                   </Grid.Col>
               </Grid>
            </Card>

            <Card className={classes.card}>
                <Grid>
                    <Grid.Col sm={7} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.TitleBsc")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2017</Text>
                            </Grid.Col>
                        </Grid>
                        <Text className={classes.cardText}>{t("projects.TextBsc")} (<a href="pdfs/thesis.pdf">Thesis</a>)</Text>
                        <Badge className={classes.badges}>Matlab</Badge>
                    </Grid.Col>
                    <Grid.Col sm={5} xs={12}>
                        <Carousel sx={{ maxWidth: 320 }} mx="auto" withIndicators withKeyboardEvents slideGap="sm" loop classNames={{control: classes.carouselControl, indicator: classes.carouselIndicator}} previousControlIcon={<IconChevronLeft size='24pt'/>} nextControlIcon={<IconChevronRight size='24pt'/>}>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/bsc/20171025_142239.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/bsc/rs_compare.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/bsc/ScreenShot.png"/>
                            </Carousel.Slide>
                        </Carousel>
                    </Grid.Col>
                </Grid>
            </Card>

            <Card className={classes.card}>
                <Grid>
                    <Grid.Col sm={7} xs={12}>
                        <Grid>
                            <Grid.Col sm={10} xs={12}>
                                <Title order={2}>{t("projects.TitleMatura")}</Title>
                            </Grid.Col>
                            <Grid.Col sm={2} xs={12}>
                                <Text align="right" color="dimmed">2013</Text>
                            </Grid.Col>
                        </Grid>
                        <Text className={classes.cardText}>{t("projects.TextMatura")}</Text>
                        <Badge className={classes.badges}>Google SketchUp</Badge>
                    </Grid.Col>
                    <Grid.Col sm={5} xs={12}>
                        <Carousel sx={{ maxWidth: 320 }} mx="auto" withIndicators withKeyboardEvents slideGap="sm" loop classNames={{control: classes.carouselControl, indicator: classes.carouselIndicator}} previousControlIcon={<IconChevronLeft size='24pt'/>} nextControlIcon={<IconChevronRight size='24pt'/>}>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/ma/b3.jpg"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/ma/FARBE_annex.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/ma/A.U.R.S.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/ma/MENSA.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/ma/collage_1.jpg"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/ma/ARCHE.png"/>
                            </Carousel.Slide>
                            <Carousel.Slide className={classes.carouselSlide}>
                                <Image src="img/ma/AULA.png"/>
                            </Carousel.Slide>
                        </Carousel>
                    </Grid.Col>
                </Grid>
            </Card>
        </>
    )
};
