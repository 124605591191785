export const i18n_english = {
    translation: {
        lang: "en",
        menu: {
            home: "home",
            about: "about me",
            projects: "projects"
        },
        home: {
            text1: "Welcome to my personal website. You can find projects that show my motivation and interest as well as information about myself. The whole site was created because I was interested to learn React and to use it in a first project. At the time of the idea I was writing applications anyway and so this website was born.",
            textProjects: "An overview of my projects from my studies and my association activities.",
            toProjects: "To the Projects",
            textAbout: "What and where I have been active in my life is listed under about me.",
            toAbout: "To my Profile",
        },
        about: {
            title: "Personality",
            jetzt: "now",
            person: {
                title: "Personal",
                anrede: "Address",
                vorname: "Firstname",
                nachname: "Name",
                nationality: "Nationality",
                nationalityText: "German with swiss residence permit C",
            },
            jobs: {
                title: "Work Experience",
                support: {
                    text: "Verband der Studierenden an der ETH Zürich as supporter in the IT-service-group:",
                    p1: "Maintenance and patching of IT systems (Linux and Windows)",
                    p2: "Maintenance of various software solutions in VMs and Container",
                    p3: "Containerizing of web application (z.B WordPress)",
                    p4: "General 1st, 2nd & 3rd level support",
                },
                ingenieur: {
                    text: "Verband der Studierenden an der ETH Zurich as Systems Engineer :",
                    p1: "Clean up and replace legacy systems",
                    p2: "Setup new IT infrastructure on Kubernetes for around 1000 users",
                    p3: "Containerizing of web application",
                }
            },
            facts: {
                title: "Skills/Experience",
                lang: "Languages",
                langText1: "German first language",
                langText2: "English written and verbal good",
                gPL: "Preferred Programming Languages",
                wPL: "Additional Programming Languages",
                web: "Web Technologies",
                db: "Databases",
                tools: "Additional Tools",
                interessen: "Interests",
                interessenText: "High Performance Computing, Computer Vision, Machine Learning, Scientific Programming"
            },
            school: {
                title: "Educational Path",
                msc: "Master Programme Computational Science and Engineering at ETH Zurich",
                bsc: "Bachelor Programme Computational Science and Engineering at ETH Zurich",
                matura: "Matura at Kantonsschule Trogen AR"
            },
            society: {
                title: "Volunteering Activities",
                sek: "SEK – Software Developing Commission of VSETH were I help as president with 5 years experience others with their first step into web developing.",
                flik: "FliK – Freiluftlichtbildschau Kkommission of VSETH Vize president and president too. 09.2021 after Corona we organized a open air cinema for 1800 person at ETH Hönggerberg.",
                vseth: "VSETH – Verband der Studierenden an der ETH Zürich Board Member in resort IT. Responsible for strategic planing and construction of a new IT infrastructure for supporting actives in there association work.",
                vmp: "VMP - Verein der Mathematik-, Physik und Rechnergestützte Wissenschaftenstudierenden an der ETH Zürich IT board member and vice president. In this year I setup a server with mail, web and file hosting.",
                vk: "Verkehrskadetten Appenzellerland with promotion to the rank of platoon leader."
            },
            hobbies:{
                title: "Hobbies",
                text: "cycling, skiing (J+S Leiter), cooking, organize events in student association , private IT-projects, board games"
            }
        },
        projects: {
            title: "Previous Projects",
            TitleHPC: "Lecture Project: High Perfomance Computing",
            titleSEK: "Software Development Committee",
            titleMsc: "Master Thesis: Spontaneous Speaker Recognition for Conversation Estimation",
            TitleWP: "Containerization of WordPress for the IT Inrastucture of VSETH",
            TitleBsc: "Bachelor Thesis: Particle Tracking for Plant Cell Study with an Adaptive Optical Flow Method",
            TitleHtwfnc: "Lecture Project: How to Write Fast Numerical Code",
            titleFLiK: "FLiK - Open Air Cinema Commission",
            TitlePbs: "Lecture Project: Physical based Simulation",
            TitleMatura: "Matura Thesis: 3D Modeling of the Cantonal School Trogen",
            TextSEK: "The Software Development Commission of VSETH supports and encourages students at ETH to learn web development and to realize projects. It creates a frame in which problems and obstacles can be looked at and solved quickly with skilled members of the commission. Currently I am the president of the commission and organize the weekly coding sessions. With about five years of experience in software development in the VSETH environment, I supervise projects of completely newcomers or give feedback to projects of experienced developers.",
            TextMsc: "The idea was to help psychologists or group coaches to evaluate group conversations. Often, they look at how the portion of speech is distributed between the various participants in the conversation. This way, the dynamics of a group can also be determined with numbers. My task was to separate voices which I did not know in advance from each other. The first attempt of labeling by feature extraction and then clustering was unfortunately not successful. So I switched to unsupervised learning and trained a neural network.",
            TextFLiK: "In 2021, I was president of the VSETH's open air cinema committee. Still during Corona, I organized a three-day open air cinema on the Hönggerberg campus of the ETH Zurich with my eight-member board. My task as president was to adapt the previous event concept from before Corona for the restriction in autumn 2021. Finally, even though we did not charge an entrance fee, we had to fence the area and do a certificate check. Luckily we could do without masks inside the area, thanks to the free air. In the end, everything went smoothly on the evenings with an average of 400 visitors.",
            TextHPC: "The task was to write an N-body simulation and then parallelize it. In the simulation up to 100'000 iron balls were calculated, which are in a rotating box. The spheres collide with an elastic collision and are also able to transmit self-rotation on contact. The edges were implemented with mirrored ghost particles. For better performance, we had to use the multigrid method to determine all collisions. This we then paralellized it using OpenMP. The difficulty was to keep the values of the physical quantities in a range of numerical stability without generating too high impulses and forces.",
            TextHtwfnc: "The task was to adapt an algorithm, which already runs optimally from the method, to a self-selected computer architecture. We were limited to use just single core. My group focused on an application of Belief Propagation to images. Belief Propagation can be used to separate the foreground and background. My part, with multiple iterations over the whole image, was to adjust the loops to make the best use of the data in the cache and load it as little often as possible. In the end, we got a significant performance increase over the original implementation.",
            TextWP: "The students' association had a dynamically grown IT infrastructure. This was based on virtual machines that were set up and maintained completely independently by the commission or student association. These VM's were not always well maintained. The most commonly used CMS was WordPress. 2018 the VSETH decided to switch to a container based solution. My task was to containerize WordPress so that it starts up automatically neatly with our surrounding system such as database, volumes, OpenID Connect including roles and SMTP. Meanwhile, due to this automatic configuration, we are running 26 instances. These all run with the same container image and differ only by the environment variables and thus a different database and volumes.",
            TextPbs: "We had to choose a simulation method from the lecture and apply it with a creative idea. We decided to do a fluid particle simulation considering portals. As known from the computer game Portal. On the one hand we implemented the numerics for the physics and on the other hand we adapted the data structure in such a way that the search for particles in the neighborhood also goes through the portals.",
            TextBsc: "The task came from biology. Pollen tubes are cells that grow comparatively quickly and even in a specific direction. The assumption is that the flow of particles in the cell itself controls the direction. To determine this more precisely, software is needed to determine this flow. My task was to track the particles from the optical microscope images using image recognition methods and to create a flow image.",
            TextMatura: "I gave myself the task to get to know digital 3D modeling by a first application. The goal was to model the buildings of the canton school Trogen AR for Google Earth. With the help of the construction plans and Google Sketchup I was able to produce very detailed 3D models of the buildings. Unfortunately, in the end it was too detailed for Google Earth and the buildings were not included in the public dataset.",
        }
    }
};
