import { Card, Grid, Title, Text, Image, List, Space } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { customStyle } from "../components/customStyle";

export function About(){
    const { t } = useTranslation();
    const { classes } = customStyle();

    return (
        <>
            <Title>{t("about.title")}</Title>
            <Space h="md"/>
            <Card className={classes.card}>
               <Grid>
                   <Grid.Col sm={7} xs={12}>
                        <Title order={2}>{t("about.person.title")}</Title>
                        <Grid>
                            <Grid.Col xs={5}>
                                <Text color="dimmed">{t("about.person.anrede")}</Text>
                            </Grid.Col>
                            <Grid.Col xs={7}>
                                <Text>Herr</Text>
                            </Grid.Col>

                            <Grid.Col xs={5}>
                                <Text color="dimmed">{t("about.person.vorname")}</Text>
                            </Grid.Col>
                            <Grid.Col xs={7}>
                                <Text>Ronan</Text>
                            </Grid.Col>

                            <Grid.Col xs={5}>
                                <Text color="dimmed">{t("about.person.nachname")}</Text>
                            </Grid.Col>
                            <Grid.Col xs={7}>
                                <Text>Lindörfer</Text>
                            </Grid.Col>

                            <Grid.Col xs={5}>
                                <Text color="dimmed">E-Mail</Text>
                            </Grid.Col>
                            <Grid.Col xs={7}>
                                <Text><a href="mailto:r.lindoerfer@gmx.ch">r.lindoerfer@gmx.ch</a></Text>
                            </Grid.Col>

                            <Grid.Col xs={5}>
                                <Text color="dimmed">{t("about.person.nationality")}</Text>
                            </Grid.Col>
                            <Grid.Col xs={7}>
                                <Text>{t("about.person.nationalityText")}</Text>
                            </Grid.Col>
                        </Grid>
                   </Grid.Col>
                   <Grid.Col sm={5} xs={12}>
                       <Image src={'img/profile.jpg'} style={{maxWidth: '270px', margin: 'auto'}} radius="sm"></Image>
                   </Grid.Col>
               </Grid>
            </Card>
            <Grid>
                <Grid.Col sm={6} xs={12}>
                    <Card className={classes.card}>
                        <Title order={2}>{t("about.jobs.title")}</Title>
                        <Grid>
                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">10.2019 - 01.2021</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.jobs.support.text")}
                                    <List>
                                        <List.Item>{t("about.jobs.support.p1")}</List.Item>
                                        <List.Item>{t("about.jobs.support.p2")}</List.Item>
                                        <List.Item>{t("about.jobs.support.p3")}</List.Item>
                                        <List.Item>{t("about.jobs.support.p4")}</List.Item>
                                    </List>
                                </Text>
                            </Grid.Col>

                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">05.2018 - 09.2018</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.jobs.ingenieur.text")}
                                    <List>
                                        <List.Item>{t("about.jobs.ingenieur.p1")}</List.Item>
                                        <List.Item>{t("about.jobs.ingenieur.p2")}</List.Item>
                                        <List.Item>{t("about.jobs.ingenieur.p3")}</List.Item>
                                    </List>
                                </Text>
                            </Grid.Col>
                        </Grid>
                    </Card>
                    <Card className={classes.card}>
                        <Title order={2}>{t("about.facts.title")}</Title>
                            <Grid>
                                <Grid.Col sm={4} xs={12}>
                                    <Text color="dimmed">{t("about.facts.lang")}</Text>
                                </Grid.Col>
                                <Grid.Col sm={8} xs={12}>
                                    <Text>
                                        {t("about.facts.langText1")}<br/>
                                        {t("about.facts.langText2")}
                                    </Text>
                                </Grid.Col>

                                <Grid.Col sm={4} xs={12}>
                                    <Text color="dimmed">{t("about.facts.gPL")}</Text>
                                </Grid.Col>
                                <Grid.Col sm={8} xs={12}>
                                    <Text>Python, C++, MATLAB</Text>
                                </Grid.Col>

                                <Grid.Col sm={4} xs={12}>
                                    <Text color="dimmed">{t("about.facts.wPL")}</Text>
                                </Grid.Col>
                                <Grid.Col sm={8} xs={12}>
                                    <Text>PHP, C, Java, Bash, Fortran, JavaScript, Ruby, R</Text>
                                </Grid.Col>

                                <Grid.Col sm={4} xs={12}>
                                    <Text color="dimmed">Frameworks</Text>
                                </Grid.Col>
                                <Grid.Col sm={8} xs={12}>
                                    <Text>OpenMP / MPI, OpenCV, Tensorflow, OpenCL, React</Text>
                                </Grid.Col>

                                <Grid.Col sm={4} xs={12}>
                                    <Text color="dimmed">{t("about.facts.web")}</Text>
                                </Grid.Col>
                                <Grid.Col sm={8} xs={12}>
                                    <Text>Nginx, Apache, NodeJs, gRPC, Django, Proxmox</Text>
                                </Grid.Col>

                                <Grid.Col sm={4} xs={12}>
                                    <Text color="dimmed">{t("about.facts.db")}</Text>
                                </Grid.Col>
                                <Grid.Col sm={8} xs={12}>
                                    <Text>SQL (MySQL, SQLite, PostgreSQL, MS SQL), LDAP</Text>
                                </Grid.Col>

                                <Grid.Col sm={4} xs={12}>
                                    <Text color="dimmed">{t("about.facts.tools")}</Text>
                                </Grid.Col>
                                <Grid.Col sm={8} xs={12}>
                                    <Text>Git, Cmake / Make, Docker/Kubernetes, Ansible, Gitlab CI/CD</Text>
                                </Grid.Col>

                                <Grid.Col sm={4} xs={12}>
                                    <Text color="dimmed">{t("about.facts.interessen")}</Text>
                                </Grid.Col>
                                <Grid.Col sm={8} xs={12}>
                                    <Text>{t("about.facts.interessenText")}</Text>
                                </Grid.Col>
                            </Grid>
                    </Card>
                </Grid.Col>
                <Grid.Col sm={6} xs={12}>
                    <Card className={classes.card}>
                        <Title order={2}>{t("about.school.title")}</Title>
                        <Grid>
                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">2018 - 2022</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.school.msc")}</Text>
                            </Grid.Col>

                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">2013 - 2018</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.school.bsc")}</Text>
                            </Grid.Col>

                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">2009 - 2013</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.school.matura")}</Text>
                            </Grid.Col>
                        </Grid>
                    </Card>
                    <Card className={classes.card}>
                        <Title order={2}>{t("about.society.title")}</Title>
                        <Grid>
                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">2021 - {t("about.jetzt")}</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.society.sek")} (<a href="https://sek.vseth.ethz.ch" target="_blank" rel="noreferrer">Website</a>)</Text>
                            </Grid.Col>

                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">2019 - 2021</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.society.flik")} (<a href="https://flik.ethz.ch" target="_blank" rel="noreferrer">Website</a>)</Text>
                            </Grid.Col>

                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">2018 - 2019</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.society.vseth")} (<a href="https://vseth.ch" target="_blank" rel="noreferrer">Website</a>)</Text>
                            </Grid.Col>

                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">2017 - 2018</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.society.vmp")} (<a href="https://vmp.ethz.ch" target="_blank" rel="noreferrer">Website</a>)</Text>
                            </Grid.Col>

                            <Grid.Col sm={3} xs={12}>
                                <Text color="dimmed">2006 - 2012</Text>
                            </Grid.Col>
                            <Grid.Col sm={9} xs={12}>
                                <Text>{t("about.society.vk")}</Text>
                            </Grid.Col>
                        </Grid>
                    </Card>
                    <Card className={classes.card}>
                        <Title order={2}>{t("about.hobbies.title")}</Title>
                        <Text>{t("about.hobbies.text")}</Text>
                    </Card>
                </Grid.Col>
            </Grid>
        </>
    )
};
