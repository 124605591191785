import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { i18n_german } from "./de";
import { i18n_english } from "./en";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: i18n_english,
    de: i18n_german,
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // whitelist: ["de", "en"],
        // nonExplicitWhitelist: true,
        fallbackLng: "en",
        debug: true,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
